<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-comment-text-multiple-outline</v-icon>
        Comentários
      </v-card-title>
      <v-divider></v-divider>

      <v-tabs fixed-tabs v-model="tab">
        <v-tab>Novo</v-tab>
        <v-tab>Enviadas</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-form ref="form" v-model="validForm" lazy-validation>
            <v-select
              v-model="selectedAssunto"
              :items="assuntos"
              style="max-width: 200px;"
              label="Assunto"
              item-text="assunto"
              item-value="id"
              hide-details
              outlined
              return-object
              dense
              class="my-5 mx-5"
              required
              :rules="formRules"
            >
            </v-select>

            <v-textarea
              outlined
              class="mx-5"
              placeholder="Insira aqui o comentário..."
              auto-grow
              required
              :rules="formRules"
              v-model="comentario.comentario"
            ></v-textarea>
          </v-form>
          <v-card outlined class="mx-5 mb-5">
            <v-file-input
              suffix="Max 100MB"
              messages="Formatos permetidos : .jpg, .png, .txt, .xls, .xlsx, .pdf, .doc, .docx, .odt."
              v-model="comentario.files"
              placeholder="Anexos"
              accept=".jpg,.png,.txt,.xls,.xlsx,.pdf,.doc,.docx,.odt"
              multiple
              class="mb-2 mx-2"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  small
                  label
                  close
                  close-icon="mdi-delete"
                  color="primary"
                  @click:close="remove(text)"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-card>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="createComentarioColaborador"
              :disabled="!validForm"
              color="secondary"
              class="white--text"
            >
              enviar <v-icon right>mdi-send-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <!--enviadas --->
        <v-tab-item><EnviadasComentarios :key="tab" /> </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { allAssuntos } from "@/api/geral/assuntos.js";
import { postComentario } from "@/api/cliente/comentarios.js";
import { mapState } from "vuex";
export default {
  name: "Comentarios",

  data() {
    return {
      tab: 0,
      comentario: {
        status : 0,
      },
      assuntos: [],
      file: null,
      selectedAssunto: null,
      validForm: true,
      formRules: [inputRequired],
    };
  },
  components: {
    EnviadasComentarios: () => import("./components/EnviadasComentarios.vue"),
  },
  watch: {
    selectedAssunto() {
      if (this.selectedAssunto) {
        this.comentario.assunto_id = this.selectedAssunto.id;
      }
    },
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },

  methods: {
    remove(item) {
      this.comentario.files.splice(
        this.comentario.files.findIndex((file) => file.name === item),
        1
      );
    },
    createComentarioColaborador() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let block = false;

        if (this.comentario.files) {
          this.comentario.files.forEach((file) => {
            if (file.size >= 104857600) {
              this.$toast.error(
                "O tamanho maximo do arquivo permitido é 100MB"
              );
              block = true;
              return;
            } else if (
              file.type.substr(-5) != "/jpeg" &&
              file.type.substr(-4) != "/png" &&
              file.type.substr(-4) != "/txt" &&
              file.type.substr(-4) != "/xls" &&
              file.type.substr(-6) != ".sheet" &&
              file.type.substr(-4) != "/pdf" &&
              file.type.substr(-4) != "/doc" &&
              file.type.substr(-5) != ".text" &&
              file.type.substr(-9) != ".document"
            ) {
              this.$toast.error("Formato não permitido");
              block = true;
              return;
            }
          });
        }

        if (block === false) {
          this.loading = true;
          const comentario = new FormData();
          comentario.append("assunto_id", this.comentario.assunto_id);
          comentario.append("user_id", this.user_id);
          comentario.append("comentario", this.comentario.comentario);
          comentario.append("status", this.comentario.status);
          if (this.comentario.files) {
            for (var i = 0; i < this.comentario.files.length; i++) {
              let file = this.comentario.files[i];

              comentario.append("files[" + i + "]", file);
            }
          }

          postComentario(comentario)
            .then((response) => {
              if (response.status === 201) {
                this.$toast.success("Comentario enviado com sucesso!");
                this.selectedAssunto = null;
                this.comentario = {};
                this.loading = false;
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.comentario.files = null;
          this.loading = false;
        }
      }
    },
    getAssuntosAll() {
      this.loading = true;
      return allAssuntos(`?status=${1}`)
        .then((response) => {
          this.assuntos = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.getAssuntosAll();
  },
};
</script>

<style></style>
