import api from "../axios_service.js";

export async function fetchComentarios(filtros) {
  const response = await api.get(
    `/cliente/comentarios${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchComentario(id) {
  const response = await api.get(`/cliente/comentarios/${id}`);
  return response.data.result;
}

export async function postComentario(item) {
  const response = await api.post("/cliente/comentarios/add", item);
  return response;
}
